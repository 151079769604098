html,
body {
	font-family: 'Open Sans';
	background-image: url("../images/back.webp");
	background-size: cover;
}
.vue-typer .custom.char {
	color: white;
}
.control.has-icons-left .icon, .control.has-icons-right .icon {
	color: orange;
}
.fade-enter-active, .fade-leave-active {
transition: opacity .5s
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
opacity: 0
}